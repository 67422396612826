<template>
  <router-link
    class="tracker-link"
    :to="{ name: 'TrackerDetails', params: { code: tracker.code } }"
  >
    <Card class="tracker-card">
      <template #title>{{ tracker.domain }}</template>

      <template #content>
        <div class="p-grid">
          <div class="p-col-9">
            <div class="p-grid tracker-card-element">
              <div class="p-col-4">
                <Tag
                  class="p-mr-2 tracker-tag"
                  value="Tracker Code"
                  rounded
                ></Tag>
              </div>
              <div class="p-col-8">
                <div class="tracker-card-element">{{ tracker.code }}</div>
              </div>
            </div>
            <div class="p-grid tracker-card-element">
              <div class="p-col-4">
                <Tag
                  class="p-mr-2 tracker-tag"
                  value="Search message"
                  rounded
                ></Tag>
              </div>
              <div class="p-col-8">
                <div class="tracker-card-element">
                  {{ tracker.search_message }}
                </div>
              </div>
            </div>

            <div class="p-grid tracker-card-element">
              <div class="p-col-4">
                <Tag class="p-mr-2 tracker-tag" value="Commands" rounded></Tag>
              </div>
              <div class="p-col-8">
                <div class="tracker-card-element">15</div>
              </div>
            </div>
            <div class="p-grid tracker-card-element">
              <div class="p-col-4">
                <Tag class="p-mr-2 tracker-tag" value="Searches" rounded></Tag>
              </div>
              <div class="p-col-8">
                <div class="tracker-card-element">12</div>
              </div>
            </div>
          </div>
          <div class="p-col-3 text-centered">
            <div>
              <div class="bigger-text">Trial left</div>
              <Tag
                class=" p-mr-2 tracker-trial-tag"
                :value="tracker.left_trial"
                severity="warning"
                rounded
              ></Tag>
            </div>

            <img
              src="@/assets/images/icons/mic.png"
              alt="Voxpow Tracker"
              class="tracker-icon p-mt-3"
            />
          </div>
        </div>
      </template>
    </Card>
  </router-link>
</template>

<script>
export default {
  props: {
    tracker: {
      type: Object,
      required: true
    }
  },
  components: {}
};
</script>

<style scoped></style>
