<template>
  <template v-if="searchIndex">
    <div class="p-grid">
      <div class="p-col-11 p-p-4 p-m-6 tracker-container">
        <div class="p-grid">
          <div class="p-col-9">
            <h1>Identificatior: {{ searchIndex.uuid }}</h1>
          </div>
          <div class="p-col-3 text-centered p-p-5"></div>
        </div>
      </div>
    </div>
  </template>

  <template v-if="!searchIndex">
    <div class="p-grid">
      <div class="p-col-11 p-p-4 p-m-6 tracker-container">
        <div class="p-grid">
          <div class="p-col-9">
            <h1>
              <Skeleton width="50%" height="1.2rem" class="p-field p-fluid" />
            </h1>
            <Skeleton width="80%" height="1.1rem" class="p-field p-fluid" />
          </div>
          <div class="p-col-3 text-centered p-p-5">
            <Skeleton width="80%" height="1.2rem" class="p-field p-fluid" />
          </div>
        </div>
        <TabView v-model:activeIndex="activeTab">
          <TabPanel>
            <template #header>
              <span class="p-pl-2"
                ><Skeleton width="100%" height="1.2rem"
              /></span>
            </template>
            <div class="p-col-10 p-offset-1">
              <div class="p-field p-fluid" v-for="n in 2" :key="n">
                <Skeleton
                  width="100%"
                  height="1.2rem"
                  class="p-field p-fluid"
                />
                <Skeleton width="80%" height="1.3rem" class="p-field p-fluid" />
                <Skeleton width="30%" height="1.1rem" class="p-field p-fluid" />
                <Skeleton width="60%" height="1.2rem" class="p-field p-fluid" />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </template>
</template>

<script>
import SearchIndexService from "@/services/SearchIndexService";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  props: {
    uuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      searchIndex: null,
      activeTab: 0
    };
  },
  created() {
    SearchIndexService.getIndex(this.uuid).then(response => {
      if (response) {
        this.searchIndex = response.data;
      }
    });
  },
  computed: {},
  methods: {},
  components: {
    TabView,
    TabPanel
  }
};
</script>

<style>
.tracker-container {
  background: #f7fafc;
  border-radius: 10px;
  border: 1px solid #e3e8ec;
}
</style>
