<template>
  <h1>
    Welcome<span v-if="firstName">, {{ firstName }}</span>
  </h1>
  <template v-if="stats">
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-lg-4">
        <router-link :to="{ name: 'TrackerCreateFirstStep' }">
          <div class="card summary light-card">
            <div class="p-grid">
              <div class="icon p-col-12 p-md-6 p-lg-3">
                <i class="pi pi-plus-circle"></i>
              </div>
              <div class="p-col-12 p-md-6 p-lg-9">
                <div class="title text-centered">Create Voice Tracker</div>
                <div class="detail text-centered">
                  {{ stats.trackers }} available so far
                </div>
                <div class="count text-centered">30 maximum allowed</div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="p-col-12 p-lg-4">
        <div class="card summary">
          <div class="p-grid">
            <div class="icon p-col-12 p-md-6 p-lg-3">
              <i class="pi pi-th-large"></i>
            </div>
            <div class="p-col-12 p-md-6 p-lg-9">
              <div class="title text-centered">Voice Commands</div>
              <div class="detail text-centered">0 available so far</div>
              <div class="count text-centered">30 maximum allowed</div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-lg-4">
        <div class="card summary">
          <div class="p-grid">
            <div class="icon p-col-12 p-md-6 p-lg-3">
              <i class="pi pi-search"></i>
            </div>
            <div class="p-col-12 p-md-6 p-lg-9">
              <div class="title text-centered">Voice Searches</div>
              <div class="detail text-centered">
                {{ stats.searches }} available so far
              </div>
              <div class="count text-centered">30 maximum allowed</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 p-lg-8">
        <h2 class="text-centered">Stats for last 30 days</h2>
        <Chart type="line" :data="lineStylesData" />
      </div>
      <div class="p-col-12 p-lg-4">
        <div class="card summary light-card">
          <div class="p-grid">
            <div class="icon p-col-12 p-md-12 p-lg-12">
              <div class="title text-centered">Last Voice Searches</div>
              <hr />
            </div>

            <div
              class="icon p-col-12 p-md-12 p-lg-12"
              v-for="index in 7"
              :key="index"
            >
              <div class="p-grid">
                <div class="icon p-col-12 p-md-6 p-lg-4">
                  <Skeleton />
                </div>
                <div class="icon p-col-12 p-md-6 p-lg-4">
                  <Skeleton />
                </div>
                <div class="icon p-col-12 p-md-6 p-lg-4">
                  <Skeleton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p>Trackers count{{ stats }}</p>
    <p>Searches {{ stats }}</p>
  </template>

  <template v-if="!stats">
    <div class="p-mx-auto text-centered">
      <ProgressSpinner />
    </div>
  </template>
</template>

<script>
import UserService from "@/services/UserService";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {
      stats: null,
      lineStylesData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July"
        ],
        datasets: [
          {
            label: "CDN Hits",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: "#42A5F5"
          },
          {
            label: "User Searches",
            data: [12, 51, 62, 33, 21, 62, 45],
            fill: true,
            borderColor: "#FFA726",
            backgroundColor: "rgba(255,167,38,0.2)"
          }
        ]
      }
    };
  },
  created() {
    UserService.getStats()
      .then(response => {
        this.stats = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  components: {},
  computed: {
    ...mapGetters({
      firstName: "user/userName"
    })
  }
};
</script>
