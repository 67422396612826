import axios from "axios";
import { ENDPOINT } from "@/utilities/constants";
import { authSetup } from "@/interceptors/authInterceptor";

const apiClient = axios.create({
  baseURL: ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

authSetup(apiClient);

export default {
  getStats() {
    return apiClient.post("/api/v1/users/stats/");
  },
  getDetails() {
    return apiClient.get("/api/v1/users/me/");
  },
  updateUser(data) {
    return apiClient.patch("/api/v1/users/me/", data);
  },
  changePassword(data) {
    return apiClient.put("/api/v1/users/change-password/", data);
  }
};
