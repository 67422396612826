import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// https://primefaces.org/primevue/showcase/#/toast
import ToastService from "primevue/toastservice";

import "primevue/resources/primevue.min.css";

// https://primefaces.org/primevue/showcase/#/icons
import "primeicons/primeicons.css";

// https://primefaces.org/primevue/showcase/#/setup
import "primevue/resources/themes/saga-blue/theme.css";

// https://primefaces.org/primevue/showcase/#/primeflex
import "primeflex/primeflex.css";

// https://primefaces.org/primevue/showcase/#/button
import Button from "primevue/button";

// https://primefaces.org/primevue/showcase/#/card
import Card from "primevue/card";

// https://www.primefaces.org/primevue/showcase/#/progressspinner
import ProgressSpinner from "primevue/progressspinner";

// https://www.primefaces.org/primevue/showcase/#/inputtext
import InputText from "primevue/inputtext";

// https://www.primefaces.org/primevue/showcase/#/dropdown
import Dropdown from "primevue/dropdown";

// https://www.primefaces.org/primevue/showcase/#/checkbox
import Checkbox from "primevue/checkbox";

// https://www.primefaces.org/primevue/showcase/#/skeleton
import Skeleton from "primevue/skeleton";

// https://www.primefaces.org/primevue/showcase/#/divider
import Divider from "primevue/divider";

// https://www.primefaces.org/primevue/showcase/#/tag
import Tag from "primevue/tag";

// https://www.primefaces.org/primevue/showcase/#/
import Steps from "primevue/steps";

// https://www.primefaces.org/primevue/showcase/#/inputswitch
import InputSwitch from "primevue/inputswitch";

// https://www.primefaces.org/primevue/showcase/#/textarea
import Textarea from "primevue/textarea";

// https://www.primefaces.org/primevue/showcase/#/dialog
import Dialog from "primevue/dialog";

// https://www.primefaces.org/primevue/showcase/#/chart
import Chart from "primevue/chart";

// primefaces.org/primevue/showcase/#/datatable
import DataTable from "primevue/datatable";

// primefaces.org/primevue/showcase/#/datatable
import Column from "primevue/column";

// primefaces.org/primevue/showcase/#/datatable
import ColumnGroup from "primevue/columngroup";

// https://www.primefaces.org/primevue/showcase/#/accordion
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

// https://ckeditor.com/blog/best-wysiwyg-editor-for-vue/
import CKEditor from "@ckeditor/ckeditor5-vue";

// Main CSS of the Dashboard
import "@/assets/css/main.css";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ToastService);
app.use(CKEditor);

app.component("Button", Button);
app.component("Card", Card);
app.component("ProgressSpinner", ProgressSpinner);
app.component("InputText", InputText);
app.component("Dropdown", Dropdown);
app.component("Checkbox", Checkbox);
app.component("Skeleton", Skeleton);
app.component("Divider", Divider);
app.component("Tag", Tag);
app.component("Steps", Steps);
app.component("InputSwitch", InputSwitch);
app.component("Textarea", Textarea);
app.component("Dialog", Dialog);
app.component("Chart", Chart);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);

app.config.globalProperties.$primevue = { ripple: true };

// TODO: Only for development
app.config.devtools = true;

app.mount("#app");
