import { createRouter, createWebHistory } from "vue-router";
import About from "../views/About.vue";
import NotFound from "../views/NotFound";
import NetworkIssue from "../views/NetworkIssue";

import TrackerCreate from "@/views/TrackerCreate";
import TrackerList from "@/views/TrackerList";
import TrackerDetails from "@/views/TrackerDetails";

import UserRegister from "@/views/UserRegister";
import UserDetails from "@/views/UserDetails";
import UserLogin from "@/views/UserLogin";

import SearchIndexList from "@/views/SearchIndexList";
import SearchIndexDetails from "@/components/search-indexes/SearchIndexDetails";

import Dashboard from "@/views/Dashboard";
import store from "@/store/index";
import TrackerCreateFirstStep from "@/components/trackers/TrackerCreateFirstStep";
import TrackerCreateSecondStep from "@/components/trackers/TrackerCreateSecondStep";
import TrackerCreateThirdStep from "@/components/trackers/TrackerCreateThirdStep";
import TrackerCreateConfirmation from "@/components/trackers/TrackerCreateConfirmation";

const routes = [
  { path: "/", redirect: "/dashboard", meta: { requiresAuth: true } },
  {
    path: "/dashboard",
    name: "Home",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/trackers",
    name: "TrackerList",
    component: TrackerList,
    meta: { requiresAuth: true }
  },
  {
    path: "/tracker/create",
    name: "TrackerCreate",
    component: TrackerCreate,
    meta: { requiresAuth: true },
    children: [
      {
        path: "domain",
        name: "TrackerCreateFirstStep",
        component: TrackerCreateFirstStep
      },
      {
        path: "settings",
        component: TrackerCreateSecondStep
      },
      {
        path: "language-style",
        component: TrackerCreateThirdStep
      },
      {
        path: "confirmation",
        component: TrackerCreateConfirmation
      }
    ]
  },
  {
    path: "/tracker/:code",
    name: "TrackerDetails",
    props: true,
    component: TrackerDetails,
    meta: { requiresAuth: true }
  },
  {
    path: "/search-indexes",
    name: "SearchIndexList",
    component: SearchIndexList,
    meta: { requiresAuth: true }
  },
  {
    path: "/search-index/:uuid",
    name: "SearchIndexDetails",
    props: true,
    component: SearchIndexDetails,
    meta: { requiresAuth: true }
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/register",
    name: "UserRegister",
    component: UserRegister
  },
  {
    path: "/login",
    name: "UserLogin",
    component: UserLogin
  },
  {
    path: "/user",
    name: "UserDetails",
    component: UserDetails,
    meta: { requiresAuth: true }
  },
  {
    path: "/logout",
    name: "LogoutUser",
    beforeEnter(to, from, next) {
      store.dispatch("user/logout");
      next(false);
    }
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
    props: true
  },
  {
    path: "/network-issue",
    name: "NetworkIssue",
    component: NetworkIssue
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "404", params: { resource: "page" } }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
