import TrackerService from "@/services/TrackerService";

export const namespaced = true;

export const state = {
  trackers: [],
  tracker: {}
};

export const mutations = {
  ADD_TRACKER(state, tracker) {
    state.trackers.push(tracker);
  },
  SET_TRACKERS(state, trackers) {
    state.trackers = trackers;
  },
  SET_TRACKER(state, tracker) {
    state.tracker = tracker;
  },
  DELETE_TRACKER(state) {
    if (state.tracker) {
      state.tracker = null;
    }
  }
};

export const actions = {
  createTracker({ commit }, tracker) {
    return TrackerService.createTracker(tracker).then(response => {
      const tracker = response.data;
      commit("ADD_TRACKER", tracker);
      commit("SET_TRACKER", tracker);
      return tracker;
    });
  },
  fetchTrackers({ commit }, { page }) {
    return TrackerService.getTrackers(page)
      .then(response => {
        commit("SET_TRACKERS", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },
  fetchTracker({ commit }, code) {
    return TrackerService.getTracker(code).then(response => {
      commit("SET_TRACKER", response.data);
    });
  },
  deleteTracker({ commit }, code) {
    return TrackerService.deleteTracker(code).then(() => {
      commit("DELETE_TRACKER");
    });
  }
};
export const getters = {
  allState(state) {
    return state;
  }
};
