import axios from "axios";
import { authSetup } from "@/interceptors/authInterceptor";
import { ENDPOINT } from "@/utilities/constants";

const apiClient = axios.create({
  baseURL: ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

authSetup(apiClient);

export default {
  getIndex(uuid) {
    return apiClient.get("/api/v1/search-indexes/" + uuid);
  },
  getSearchIndexes(page = 1) {
    return apiClient.get("/api/v1/search-indexes/?page=" + page);
  }
};
