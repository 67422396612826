import axios from "axios";
import { authSetup } from "@/interceptors/authInterceptor";
import { ENDPOINT } from "@/utilities/constants";

const apiClient = axios.create({
  baseURL: ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

authSetup(apiClient);

export default {
  getCommands(page = 1) {
    return apiClient.get("/api/v1/commands/?page=" + page);
  },
  getCommandsTracker(page = 1, tracker) {
    return apiClient.get(
      "/api/v1/commands/?page=" + page + "&tracker=" + tracker
    );
  },
  getCommand(uuid) {
    return apiClient.get("/api/v1/commands/" + uuid + "/");
  },
  createCommand(command) {
    return apiClient.post("/api/v1/commands/", command);
  },
  updateTracker(uuid, data) {
    return apiClient.patch("/api/v1/commands/" + uuid + "/", data);
  },
  deleteCommand(uuid) {
    return apiClient.delete("/api/v1/commands/" + uuid + "/");
  }
};
