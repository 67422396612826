import axios from "axios";
import { authSetup } from "@/interceptors/authInterceptor";
import { ENDPOINT } from "@/utilities/constants";

const apiClient = axios.create({
  baseURL: ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

authSetup(apiClient);

export default {
  getTrackers(page = 1) {
    return apiClient.get("/api/v1/trackers/?page=" + page);
  },
  getTracker(code) {
    return apiClient.get("/api/v1/trackers/" + code + "/");
  },
  createTracker(tracker) {
    return apiClient.post("/api/v1/trackers/", tracker);
  },
  updateTracker(code, data) {
    return apiClient.patch("/api/v1/trackers/" + code + "/", data);
  },
  deleteTracker(code) {
    return apiClient.delete("/api/v1/trackers/" + code + "/");
  }
};
