import { createStore } from "vuex";
import * as user from "@/store/modules/user";
import * as tracker from "@/store/modules/tracker";

export default createStore({
  state: {},
  modules: {
    user,
    tracker
  },
  getters: {}
});
