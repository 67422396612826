<template>
  <template v-if="tracker">
    <div class="p-grid">
      <div class="p-col-11 p-p-4 p-m-6 tracker-container">
        <div class="p-grid">
          <div class="p-col-9">
            <h1>Domain: {{ tracker.domain }}</h1>
          </div>
          <div class="p-col-3 text-centered p-p-5">
            <Tag
              class="p-mr-2 tracker-tag"
              :value="`${tracker.left_trial} trial Left`"
              rounded
            />
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-6">
            <h2>Code: {{ tracker.code }}</h2>
          </div>
          <div class="p-col-6 text-centered p-p-5"></div>
        </div>

        <TabView v-model:activeIndex="activeTab">
          <TabPanel>
            <template #header>
              <i class="pi pi-cog" :class="{ unsaved: basicUsaved }"></i>
              <span class="p-pl-2" :class="{ unsaved: basicUsaved }"
                >Basic</span
              >
            </template>
            <div class="p-col-10 p-offset-1">
              <!-- Search Message -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Search Message
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <InputText
                    type="text"
                    v-model="tracker.search_message"
                    class="width-70"
                    @click="basicUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Enter the text which will be shown to the customer when
                  performing Voice Searches
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Use voice typing-->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Allow users to type with voice
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 text-centered">
                  <InputSwitch
                    id="use-voice-typing"
                    v-model="tracker.use_voice_typing"
                    @change="basicUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  You can encourage your visitors to use the tracker more
                  frequently, adding a small pulsing popup
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Type Message -->
              <div class="p-grid" v-if="tracker.use_voice_typing">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Type Message
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <InputText
                    type="text"
                    v-model="tracker.write_message"
                    class="width-70"
                    @click="basicUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Enter the text which will be shown to the customer when using
                  Typing with Voice feature
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Language -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Language
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <Dropdown
                    v-model="selectedLang"
                    :options="languages"
                    optionLabel="name"
                    placeholder="Select a Lang"
                    :filter="true"
                    class="width-70"
                    @change="basicUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Language of the customers, who will use the tracker
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Show popup for interactivity -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Show interactive popup
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 text-centered">
                  <InputSwitch
                    id="show-interactive-popup"
                    v-model="tracker.show_popup"
                    @change="basicUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  You can encourage your visitors to use the tracker more
                  frequently, adding a small pulsing popup
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Interactive Popup message -->
              <div class="p-grid" v-if="tracker.show_popup">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Popup message
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <InputText
                    type="text"
                    v-model="tracker.popup_message"
                    class="width-70"
                    @click="basicUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Enter the text wich will be shown into the popup
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Theme -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Theme
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <Dropdown
                    v-model="selectedTheme"
                    :options="themes"
                    optionLabel="name"
                    placeholder="Select a Theme"
                    :filter="true"
                    class="width-70"
                    @change="basicUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Design of your Voice Tracker. You can choose the pre-defined
                  or custom design
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Theme Visualizer -->
              <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 text-centered">
                  <img
                    :src="
                      require(`@/assets/images/trackers/${selectedTheme.image}`)
                    "
                    :alt="selectedTheme.name"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Just a demo preview of your tracker
                </div>
              </div>

              <!-- Save Button -->
              <div
                class="save-button-fixed text-centered"
                v-if="basicUsaved > 0"
              >
                <div class="p-col-12 p-md-12 p-lg-12 text-centered">
                  <Button label="Save Changes" @click="saveBasicChanges()" />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-cog" :class="{ unsaved: advancedUsaved }"></i>
              <span class="p-pl-2" :class="{ unsaved: advancedUsaved }"
                >Advanced</span
              >
            </template>
            <div class="p-col-10 p-offset-1">
              <!-- Fallback Type -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Fallback Type
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <Dropdown
                    v-model="fallbackType"
                    :options="fallbackTypes"
                    optionLabel="name"
                    placeholder="Select a Fallback Type"
                    :filter="true"
                    class="width-70"
                    @change="advancedUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  The fallback type when the user search doesn't match any
                  patterns
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Search URL -->
              <div class="p-grid" v-if="fallbackType.type === 'search-url'">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Search URL
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <InputText
                    type="text"
                    v-model="tracker.search_url"
                    class="width-70"
                    @change="advancedUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  The URL of your built-in search functionality, for example:
                  https://example.com/?s=
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Custom JavaScript Function -->
              <div
                class="p-grid"
                v-if="fallbackType.type === 'custom-function'"
              >
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Custom JS Function
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <InputText
                    type="text"
                    v-model="tracker.custom_fallback_function"
                    class="width-70"
                    @change="advancedUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  JavaScript function, which you can use for a fallback
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Interactive Fallback Prefix field -->
              <div
                class="p-grid"
                v-if="
                  fallbackType.type === 'static-popup' ||
                    fallbackType.type === 'search-popup'
                "
              >
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Interactive Fallback Prefix
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                  <InputText
                    type="text"
                    v-model="tracker.interactive_fallback_prefix"
                    class="width-70"
                    @change="advancedUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  The text which will be into the Interactive Fallback Popup
                  heading
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Interactive Fallback Content field -->
              <div
                class="p-grid"
                v-if="
                  fallbackType.type === 'static-popup' ||
                    fallbackType.type === 'search-popup'
                "
              >
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Interactive Fallback Content
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                  <ckeditor
                    :editor="editor"
                    v-model="tracker.interactive_fallback_content"
                    :config="editorConfig"
                    class="width-70"
                    @blur="advancedUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Main content (HTML) of the Interactive Fallback Popup
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Remember Status -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Remember Status
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 text-centered">
                  <InputSwitch
                    id="remember-status"
                    v-model="tracker.remember_status"
                    @change="advancedUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Should we remember when the user clicks the button to turn on
                  Speech Recognition on your website?
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Show only on mobile devices-->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Show only on Mobile
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 text-centered">
                  <InputSwitch
                    id="show-only-mobile"
                    v-model="tracker.show_only_on_mobile"
                    @change="advancedUsaved++"
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Turn on this option to restrict the desktop users to use
                  Voxpow and show only to mobile devices
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Delete Tracker Button -->
              <div class="p-col-12 text-centered">
                <Button
                  @click="deleteTrackerConfirmation()"
                  icon="pi pi-times"
                  label="Delete Tracker"
                  class="p-button-danger p-button-outlined"
                ></Button>
              </div>
              <!-- Delete Tracker Dialog -->
              <Dialog v-model:visible="displayDeleteTracker">
                <template #header>
                  <h3>Are you are sure?</h3>
                </template>

                The action you are performing will permanently delete tracker
                {{ tracker.code }} for domain {{ tracker.domain }}.

                <template #footer>
                  <div class="text-centered">
                    <Button
                      label="Yes, delete"
                      icon="pi pi-times"
                      autofocus
                      class="p-button-danger p-button-outlined"
                      @click="deleteTracker(tracker)"
                    />
                  </div>
                </template>
              </Dialog>

              <!-- Save Button -->
              <div
                class="save-button-fixed text-centered"
                v-if="advancedUsaved > 0"
              >
                <div class="p-col-12 p-md-12 p-lg-12 text-centered">
                  <Button label="Save Changes" @click="saveAdvancedChanges()" />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-chart-bar"></i>
              <span class="p-pl-2">Analytics & Stats</span>
            </template>
            <div class="p-col-10 p-offset-1">
              <!-- Save User Searches -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Save User Searches
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 text-centered">
                  <InputSwitch
                    id="save-user-searches"
                    v-model="tracker.save_searches"
                    @change="
                      onTrackerFieldChange(
                        'save_searches',
                        tracker.save_searches
                      )
                    "
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Should we keep track of the user searches? You can analyze
                  them after in the dashboard
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Send Events Analytics -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Send Events Analytics
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 text-centered">
                  <InputSwitch
                    id="send-events-analytics"
                    v-model="tracker.send_events_analytics"
                    @change="
                      onTrackerFieldChange(
                        'send_events_analytics',
                        tracker.send_events_analytics
                      )
                    "
                  />
                </div>
                <div class="p-col-12 field-hint text-centered">
                  Do you want to send events to Google Analytics to better
                  understand your customers?
                </div>
                <Divider type="dashed" />
              </div>

              <!-- Usage Stats -->
              <div class="p-col-12 p-lg-8 p-mx-auto">
                <h2 class="text-centered">Stats for last 30 days</h2>
                <Chart type="line" :data="trackerUsageStats" />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-tags"></i>
              <span class="p-pl-2">Commands</span>
            </template>
            <div class="p-col-10 p-offset-1">
              <div class="p-fluid p-mb-4">
                <Accordion>
                  <AccordionTab>
                    <template #header>
                      <span>Add new Voice Command</span>
                    </template>

                    <!-- Command sentence field -->
                    <div class="p-grid">
                      <div class="p-col-12 p-md-6 p-lg-4">
                        <div class="text-right p-p-1 bigger-text">
                          Command Sentence
                        </div>
                      </div>
                      <div class="p-col-12 p-md-6 p-lg-8">
                        <InputText
                          type="text"
                          v-model="command.sentence"
                          class="p-column-filter width-70"
                        />
                      </div>
                      <div class="p-col-12 field-hint text-centered">
                        Enter the sentence which triggers the command
                      </div>
                      <Divider type="dashed" />
                    </div>

                    <!-- Destination URL or  action -->
                    <div class="p-grid">
                      <div class="p-col-12 p-md-6 p-lg-4">
                        <div class="text-right p-p-1 bigger-text">
                          Destination URL
                        </div>
                      </div>
                      <div class="p-col-12 p-md-6 p-lg-8">
                        <InputText
                          type="text"
                          v-model="command.destination_url"
                          class="p-column-filter width-70"
                        />
                      </div>
                      <div class="p-col-12 field-hint text-centered">
                        Enter the sentence which triggers the command
                      </div>
                      <Divider type="dashed" />
                    </div>

                    <!-- Type of command -->
                    <div class="p-grid">
                      <div class="p-col-12 p-md-6 p-lg-4">
                        <div class="text-right p-p-1 bigger-text">
                          Type of command
                        </div>
                      </div>
                      <div class="p-col-12 p-md-6 p-lg-6">
                        <Dropdown
                          v-model="command.type"
                          :options="commandTypes"
                          optionLabel="name"
                          placeholder="Select a type of the command"
                          class="width-95"
                        />
                      </div>
                      <div class="p-col-12 field-hint text-centered">
                        Type of the command
                      </div>
                      <Divider type="dashed" />
                    </div>

                    <!-- Create command button -->
                    <div class="p-grid">
                      <div class="p-col-8 p-offset-2">
                        <Button
                          label="Add new command"
                          class="p-button-secondary"
                          @click="addCommand"
                        />
                      </div>
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
              <!-- List with commands-->
              <div class="p-grid">
                <div class="p-col-12 text-centered">
                  <DataTable
                    :value="tracker.commands"
                    :paginator="true"
                    :rows="10"
                  >
                    <Column
                      field="sentence"
                      header="Sentence"
                      sortable="true"
                    ></Column>
                    <Column
                      field="destination_url"
                      header="Outcome"
                      sortable="true"
                    ></Column>
                    <Column
                      field="type"
                      header="Type of Command"
                      sortable="true"
                    ></Column>
                    <Column
                      headerStyle="width: 8rem; text-align: center"
                      bodyStyle="text-align: center; overflow: visible"
                      header="Actions"
                    >
                      <template #body>
                        <Button
                          type="button"
                          icon="pi pi-times"
                          class="p-button-danger"
                        ></Button>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-search-plus"></i>
              <span class="p-pl-2">Searches</span>
            </template>
            <div class="p-col-10 p-offset-1">
              <!-- List with searches-->
              <div class="p-grid">
                <div class="p-col-12 text-centered">
                  <DataTable
                    :value="tracker.searches"
                    :paginator="true"
                    :rows="10"
                  >
                    <Column
                      field="text"
                      header="Searched text"
                      sortable="true"
                    ></Column>
                    <Column
                      field="user_agent"
                      header="User Agent"
                      sortable="true"
                    ></Column>
                    <Column
                      field="url"
                      header="Where it is made?"
                      sortable="true"
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-money-bill"></i>
              <span class="p-pl-2">Subscriptions</span>
            </template>
            <div class="p-col-10 p-offset-1">
              <!-- Is the tracker PRO -->
              <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Is PRO?
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div
                    v-if="!tracker.is_pro"
                    class="text-right p-p-1 bigger-text"
                  >
                    No, make it PRO
                  </div>
                </div>
                <Divider type="dashed" />
              </div>
              <!-- Subscription start -->
              <div v-if="tracker.subscription_start" class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    Subscription start
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-4">
                  <div class="text-right p-p-1 bigger-text">
                    {{ tracker.subscription_start }}
                  </div>
                </div>
                <Divider type="dashed" />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-users"></i>
              <span class="p-pl-2">Permissions</span>
            </template>
            <h2>
              <label>Is Active?</label>
              <Checkbox
                v-model="tracker.is_active"
                :binary="true"
                @change="onTrackerFieldChange('is_active', tracker.is_active)"
              />
            </h2>

            <h2>
              Language
              <Dropdown
                v-model="selectedLang"
                :options="languages"
                optionLabel="name"
                placeholder="Select a Lang"
                :filter="true"
                :showClear="true"
                @change="onTrackerFieldChange('language', selectedLang.code)"
              />
            </h2>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-check-square"></i>
              <span class="p-pl-2">Installation</span>
            </template>
            <h2>
              <label>Is Active?</label>
              <Checkbox
                v-model="tracker.is_active"
                :binary="true"
                @change="onTrackerFieldChange('is_active', tracker.is_active)"
              />
            </h2>

            <h2>
              Language
              <Dropdown
                v-model="selectedLang"
                :options="languages"
                optionLabel="name"
                placeholder="Select a Lang"
                :filter="true"
                :showClear="true"
                @change="onTrackeerFieldChange('language', selectedLang.code)"
              />
            </h2>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </template>

  <template v-if="!tracker">
    <div class="p-grid">
      <div class="p-col-11 p-p-4 p-m-6 tracker-container">
        <div class="p-grid">
          <div class="p-col-9">
            <h1>
              <Skeleton width="50%" height="1.2rem" class="p-field p-fluid" />
            </h1>
            <Skeleton width="80%" height="1.1rem" class="p-field p-fluid" />
          </div>
          <div class="p-col-3 text-centered p-p-5">
            <Skeleton width="80%" height="1.2rem" class="p-field p-fluid" />
          </div>
        </div>
        <TabView v-model:activeIndex="activeTab">
          <TabPanel>
            <template #header>
              <span class="p-pl-2"
                ><Skeleton width="100%" height="1.2rem"
              /></span>
            </template>
            <div class="p-col-10 p-offset-1">
              <div class="p-field p-fluid" v-for="n in 2" :key="n">
                <Skeleton
                  width="100%"
                  height="1.2rem"
                  class="p-field p-fluid"
                />
                <Skeleton width="80%" height="1.3rem" class="p-field p-fluid" />
                <Skeleton width="30%" height="1.1rem" class="p-field p-fluid" />
                <Skeleton width="60%" height="1.2rem" class="p-field p-fluid" />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </template>
</template>

<script>
import TrackerService from "@/services/TrackerService.js";
import CommandService from "@/services/CommandService";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {
  LANGUAGES,
  THEMES,
  FALLBACK_TYPES,
  COMMAND_TYPES
} from "@/utilities/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: {
    code: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tracker: null,
      activeTab: 0,
      commandsCount: "0",
      searchesCount: "0",
      selectedLang: LANGUAGES[0],
      selectedTheme: THEMES[0],
      fallbackType: FALLBACK_TYPES[0],
      languages: LANGUAGES,
      fallbackTypes: FALLBACK_TYPES,
      themes: THEMES,
      displayDeleteTracker: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList"
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      },
      trackerUsageStats: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July"
        ],
        datasets: [
          {
            label: "CDN Hits",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: "#42A5F5"
          },
          {
            label: "User Searches",
            data: [12, 51, 62, 33, 21, 62, 45],
            fill: true,
            borderColor: "#FFA726",
            backgroundColor: "rgba(255,167,38,0.2)"
          }
        ]
      },
      command: {
        sentence: null,
        destination_url: null,
        type: COMMAND_TYPES[0],
        priority: null,
        tracker: null
      },
      commandTypes: COMMAND_TYPES,
      basicUsaved: 0,
      advancedUsaved: 0
    };
  },
  created() {
    TrackerService.getTracker(this.code).then(response => {
      if (response) {
        this.tracker = response.data;
        // Find the whole information from the local array (Mapping)
        this.selectedLang = this.languages.find(function(language) {
          if (language.code === response.data.language) return true;
        });
        // Find the whole information from the local array (Mapping)
        this.selectedTheme = this.themes.find(function(theme) {
          if (theme.path === response.data.theme) return true;
        });
        // Find the whole information from the local array (Mapping)
        this.fallbackType = this.fallbackTypes.find(function(fallback) {
          if (fallback.type === response.data.fallback_type) return true;
        });
        this.commandsCount = response.data.commands.length.toString();
        // Assign the tracker code to command instance
        this.command.tracker = this.tracker.code;
      }
    });
  },
  computed: {},
  methods: {
    onMounted(editor) {
      console.log("ckeditor mounted");
      this.editorInstance = editor;
    },
    saveBasicChanges() {
      let data = {};
      data["search_message"] = this.tracker.search_message;
      data["use_voice_typing"] = this.tracker.use_voice_typing;
      data["write_message"] = this.tracker.write_message;
      data["language"] = this.selectedLang.code;
      data["show_popup"] = this.tracker.show_popup;
      data["popup_message"] = this.tracker.popup_message;
      data["theme"] = this.selectedTheme.path;
      TrackerService.updateTracker(this.tracker.code, data).then(
        (this.basicUsaved = 0),
        this.$toast.add({
          severity: "success",
          summary: "Basic settings updated!",
          detail: "Your settings are saved",
          life: 3000
        })
      );
    },
    saveAdvancedChanges() {
      let data = {};
      data["fallback_type"] = this.fallbackType.type;
      data["search_url"] = this.tracker.search_url;
      data["custom_fallback_function"] = this.tracker.custom_fallback_function;
      data[
        "interactive_fallback_prefix"
      ] = this.tracker.interactive_fallback_prefix;
      data[
        "interactive_fallback_content"
      ] = this.tracker.interactive_fallback_content;
      data["remember_status"] = this.tracker.remember_status;
      data["show_only_on_mobile"] = this.tracker.show_only_on_mobile;
      TrackerService.updateTracker(this.tracker.code, data).then(
        (this.advancedUsaved = 0),
        this.$toast.add({
          severity: "success",
          summary: "Advanced settings are updated!",
          detail: "Your settings are saved",
          life: 3000
        })
      );
    },
    onTrackerFieldChange(key, value) {
      this.basicUsaved++;
      const data = {};
      data[key] = value;
      TrackerService.updateTracker(this.tracker.code, data).then(
        (key = key.replaceAll("_", " ").toUpperCase()),
        this.$toast.add({
          severity: "success",
          summary: key + " updated!",
          detail: "Your settings are saved",
          life: 3000
        })
      );
    },
    deleteTrackerConfirmation() {
      this.displayDeleteTracker = !this.displayDeleteTracker;
    },
    deleteTracker(tracker) {
      this.$store
        .dispatch("tracker/deleteTracker", tracker.code)
        .then(() => {
          this.$toast.add({
            severity: "warn",
            summary: `You deleted tracker ${tracker.code}`,
            detail: `Tracker for domain ${tracker.domain} was removed`,
            life: 3000
          });

          this.$router.push({
            name: "TrackerList"
          });
        })
        .catch(error => {
          if (error.status === 204 || error.status === 404) {
            this.$toast.add({
              severity: "warn",
              summary: `You deleted tracker ${tracker.code}`,
              detail: `Tracker for domain ${tracker.domain} was removed`,
              life: 3000
            });

            this.$router.push({
              name: "TrackerList"
            });
          }
        });
    },
    addCommand() {
      // validate
      // Assign the dict value to field in order to be validated in API
      this.command.type = this.command.type.type;

      CommandService.createCommand(this.command).then(({ data }) => {
        // Push to list with actve commands
        this.tracker.commands.push(data);

        // Add a notification
        this.$toast.add({
          severity: "success",
          summary: "Command created",
          detail: "Your settings are saved",
          life: 3000
        });
      });

      // Null the object
      this.command = {
        sentence: null,
        destination_url: null,
        type: COMMAND_TYPES[0],
        priority: null,
        tracker: this.tracker.code
      };
    }
  },
  components: {
    TabView,
    TabPanel
  }
};
</script>

<style>
.tracker-container {
  background: #f7fafc;
  border-radius: 10px;
  border: 1px solid #e3e8ec;
}
</style>
