<template>
  <template v-if="user">
    <div class="p-grid">
      <div class="p-col-11 p-p-4 p-m-6 user-container">
        <h1>Email: {{ user.email }}</h1>
        <h2>Registered at: {{ user.registered_at }}</h2>

        <div class="card">
          <TabView class="tabview-custom" v-model:activeIndex="activeTab">
            <TabPanel>
              <template #header>
                <i class="pi pi-calendar"></i>
                <span class="p-pl-2">Main settings</span>
              </template>
              <!-- First Name user field -->
              <div class="p-col-10 p-offset-1">
                <div class="p-grid">
                  <div class="p-col-12 p-md-6 p-lg-4">
                    <div class="text-right p-p-1 bigger-text">
                      First Name
                    </div>
                  </div>
                  <div class="p-col-12 p-md-6 p-lg-8">
                    <InputText
                      id="first-name"
                      type="first-name"
                      aria-describedby="name-help"
                      v-model="user.first_name"
                      class="width-70"
                      @change="
                        onUserFieldChange('first_name', user.first_name, true)
                      "
                      :class="{ 'p-invalid': validationErrors.firstName }"
                    />
                  </div>
                  <div class="p-col-12 field-hint text-centered">
                    Enter your username to reset your password
                  </div>
                  <Divider type="dashed" />
                </div>
              </div>

              <!-- Last Name user field -->
              <div class="p-col-10 p-offset-1">
                <div class="p-grid">
                  <div class="p-col-12 p-md-6 p-lg-4">
                    <div class="text-right p-p-1 bigger-text">
                      Last Name
                    </div>
                  </div>
                  <div class="p-col-12 p-md-6 p-lg-8">
                    <InputText
                      id="last-name"
                      type="last-name"
                      aria-describedby="name-help"
                      v-model="user.last_name"
                      class="width-70"
                      @change="
                        onUserFieldChange('last_name', user.last_name, true)
                      "
                      :class="{ 'p-invalid': validationErrors.last - name }"
                    />
                  </div>
                  <div class="p-col-12 field-hint text-centered">
                    Enter your last name
                  </div>
                  <Divider type="dashed" />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <i class="pi pi-user"></i>
                <span class="p-pl-2">Affiliates</span>
              </template>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt. Consectetur, adipisci velit, sed quia non numquam eius
                modi.
              </p>
            </TabPanel>
            <TabPanel>
              <template #header>
                <i class="pi pi-cog"></i>
                <span class="p-pl-2">Payments</span>
              </template>
              <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                rerum facilis est et expedita distinctio. Nam libero tempore,
                cum soluta nobis est eligendi optio cumque nihil impedit quo
                minus.
              </p>
            </TabPanel>
            <TabPanel>
              <template #header>
                <i class="pi pi-key"></i>
                <span class="p-pl-2">Change password</span>
              </template>
              <div class="p-col-10 p-offset-1">
                <div class="p-grid">
                  <div class="p-col-12 p-md-6 p-lg-4">
                    <div class="text-right p-p-1 bigger-text">
                      Old Password
                    </div>
                  </div>
                  <div class="p-col-12 p-md-6 p-lg-8">
                    <InputText
                      id="old-password"
                      type="password"
                      v-model="oldPassword"
                      aria-describedby="name-help"
                      class="width-70"
                    />
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col-12 p-md-6 p-lg-4">
                    <div class="text-right p-p-1 bigger-text">
                      New Password
                    </div>
                  </div>
                  <div class="p-col-12 p-md-6 p-lg-8">
                    <InputText
                      id="new-password"
                      type="password"
                      v-model="newPassword"
                      aria-describedby="name-help"
                      class="width-70"
                    />
                  </div>
                </div>
                <div class="p-col-12 text-centered">
                  <Button
                    @click="changePassword()"
                    label="Change Password"
                    class="p-button-primary p-button-outlined"
                  ></Button>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  </template>

  <template v-if="!user">
    <!-- Skeleton if we still don't  have the instance to show -->
    <div class="p-mx-auto text-centered">
      <div class="p-grid">
        <div class="p-col-11 p-p-4 p-m-6 user-container">
          <h1>
            <Skeleton width="100%" height="2rem" />
          </h1>
          <h2>
            <Skeleton width="70%" height="1.2rem" />
          </h2>
          <div class="card">
            <TabView class="tabview-custom">
              <TabPanel>
                <template #header>
                  <Skeleton width="10%" height="1.2rem" />
                </template>
                <div class="p-field p-fluid">
                  <Skeleton width="100%" height="1.6rem" />
                </div>
                <div class="p-field p-fluid">
                  <Skeleton width="80%" height="2rem" />
                </div>
                <div class="p-field p-fluid">
                  <Skeleton width="50%" height="1.1rem" />
                </div>
                <div class="p-field p-fluid">
                  <Skeleton width="70%" height="1.2rem" />
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import UserService from "@/services/UserService";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  data() {
    return {
      user: null,
      activeTab: 0,
      validationErrors: {},
      oldPassword: null,
      newPassword: null,
      newPasswordAgain: null
    };
  },
  created() {
    this.$store.dispatch("user/fetchInfo").then(data => {
      this.user = data;
    });
  },
  methods: {
    onUserFieldChange(key, value, required = false) {
      const data = {};
      let validField = true;

      if (required) {
        validField = this.validateField(key, value);
      }

      if (validField) {
        data[key] = value;
        UserService.updateUser(data).then(
          (key = key.replace("_", " ").toUpperCase()),
          this.$toast.add({
            severity: "success",
            summary: "Field " + key + " updated!",
            detail: "Your settings are saved",
            life: 3000
          })
        );
      }
    },
    validateField(field, value) {
      if (!value.trim()) this.validationErrors[field] = true;
      else delete this.validationErrors[field];
      return !Object.keys(this.validationErrors).length;
    },
    changePassword() {
      const data = {};
      if (this.oldPassword == null || this.newPassword == null) {
        this.$toast.add({
          severity: "error",
          summary: "Passwords cannot be empty",
          detail: "Please fill passwords to change",
          life: 3000
        });
      } else {
        data["old_password"] = this.oldPassword;
        data["new_password"] = this.newPassword;
        UserService.changePassword(data).then(res => {
          if (res.status === 200 && res.data.status === "success") {
            this.$toast.add({
              severity: "success",
              summary: "You changed the password successfully",
              detail: "You can login with the new password",
              life: 3000
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Passwords is not correct",
              detail: "Please fill the right password",
              life: 3000
            });
          }

          // Clear the input fields
          this.oldPassword = null;
          this.newPassword = null;
        });
      }
    }
  },
  components: {
    TabView,
    TabPanel
  }
};
</script>

<style scoped>
.user-container {
  background: #f7fafc;
  border-radius: 10px;
  border: 1px solid #e3e8ec;
}
</style>
