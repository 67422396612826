<template>
  <div class="p-col-12 p-md-6 p-lg-4 p-md-offset-4 login-container">
    <div class="p-d-flex">
      <img
        src="@/assets/images/logo-dark.png"
        alt="Voxpow Login"
        style="width: 90px"
      />
    </div>
    <h2 class="text-centered">Login</h2>
    <div class="p-fluid">
      <form @submit.prevent="login">
        <div class="p-field p-grid">
          <label for="email" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
            >Email</label
          >
          <div class="p-col-12 p-md-10">
            <InputText
              id="email"
              type="email"
              aria-describedby="email-help"
              autocomplete="email"
              v-model="email"
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
            >Password</label
          >
          <div class="p-col-12 p-md-10">
            <InputText
              id="password"
              type="password"
              aria-describedby="password-help"
              autocomplete="current-password"
              v-model="password"
            />
          </div>
        </div>
        <p>{{ error }}</p>

        <Button label="Login" type="submit" class="p-button-secondary" />

        <div class="p-mt-5 text-centered">
          <a href="https://voxpow.com/accounts/signup/">
            Don't have an account? Register.
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      error: null
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("user/login", {
          username: this.email,
          password: this.password
        })

        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success Login",
            detail: "You are now in :)",
            life: 4000
          });

          this.$store.dispatch("user/fetchInfo");

          this.$router.push({ name: "TrackerList" });
        })
        .catch(err => {
          this.error = err.response;
        });
    }
  }
};
</script>

<style scoped>
.login-container {
  background: #f7fafc;
  padding: 40px;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid;
}
</style>
