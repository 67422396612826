import router from "@/router";
import store from "@/store/index";
import { ENDPOINT } from "@/utilities/constants";
import axios from "axios";

function authInterceptor() {
  return function(requestConfig) {
    const token = JSON.parse(localStorage.getItem("user")).access;
    if (token) {
      requestConfig.headers["Authorization"] = `Bearer ${token}`;
    }

    return requestConfig;
  };
}

function responseInterceptor() {
  return function(response) {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  };
}

function responseInterceptorError() {
  return function(error) {
    if (error.response.status) {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        originalRequest.url === ENDPOINT + "/api/v1/token/refresh/"
      ) {
        router.push("/login");
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refresh = JSON.parse(localStorage.getItem("user")).refresh;
        return axios
          .post(ENDPOINT + "/api/v1/token/refresh/", {
            refresh: refresh
          })
          .then(res => {
            if (res.status === 200) {
              // 1) put token to LocalStorage
              const data = {
                access: res.data.access,
                refresh: refresh
              };
              store.dispatch("user/refresh", data);

              // 2) Change Authorization header
              originalRequest.headers["Authorization"] =
                "Bearer " + res.data.access;

              // 3) return originalRequest object with Axios.
              return axios(originalRequest);
            }
          })
          .catch(() => {
            store.dispatch("user/logout");
            router.push("/login");
            return Promise.reject(error);
          });
      }

      return Promise.reject(error);
    }
  };
}

export function authSetup(axiosInstance) {
  axiosInstance.interceptors.request.use(authInterceptor());
  axiosInstance.interceptors.response.use(
    responseInterceptor(),
    responseInterceptorError()
  );
}
