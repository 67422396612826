<template>
  <div class="tracker-create-steps p-p-2 p-mt-3">
    <Card>
      <template v-slot:title>
        <h3 class="text-centered">Main Configuration</h3>
      </template>
      <template v-slot:content>
        <div class="p-col-10 p-offset-1">
          <!-- Domain field -->
          <div class="p-fluid p-mt-2">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Domain <sup>*</sup></div>
              <div class="p-col-9">
                <InputText
                  id="domain"
                  v-model="domain"
                  :class="{ 'p-invalid': validationErrors.domain && submitted }"
                />
                <div
                  v-show="validationErrors.domain && submitted"
                  class="p-error field-hint text-centered"
                >
                  The domain is a required field.
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              Domain of the site, which will use the tracker
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Search Message field -->
          <div class="p-fluid p-mt-4">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Search Message</div>
              <div class="p-col-9">
                <InputText
                  id="search-message"
                  v-model="searchMessage"
                  :class="{
                    'p-invalid': validationErrors.searchMessage && submitted
                  }"
                />
                <div
                  v-show="validationErrors.searchMessage && submitted"
                  class="p-error field-hint text-centered"
                >
                  Your search message is required.
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              Enter the text which will be shown to the customer when performing
              Voice Searches
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Allow users to type with voice field -->
          <div class="p-fluid p-mt-4">
            <div class="p-field">
              <div class="p-grid">
                <div class="p-col-7 bigger-text">
                  Allow users to type with voice
                </div>
                <div class="p-col-5">
                  <InputSwitch id="use-voice-typing" v-model="useVoiceTyping" />
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              Do you want to give your visitors the ability to Type with their
              Voice in different text or input fields?
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Type message field -->
          <div class="p-fluid p-mt-4" v-if="useVoiceTyping">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Type Message</div>
              <div class="p-col-9">
                <InputText
                  id="type-message"
                  v-model="typeMessage"
                  :class="{
                    'p-invalid': validationErrors.typeMessage && submitted
                  }"
                />
                <div
                  v-show="validationErrors.typeMessage && submitted"
                  class="p-error"
                >
                  Your type message is required.
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              Enter the text which will be shown to the customer when using
              Typing with Voice feature
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Show interactive popup field -->
          <div class="p-fluid p-mt-4">
            <div class="p-field">
              <div class="p-grid">
                <div class="p-col-7 bigger-text">
                  Show interactive popup
                </div>
                <div class="p-col-5">
                  <InputSwitch
                    id="show-interactive-popup"
                    v-model="showInteractivePopup"
                    :class="{
                      'p-invalid':
                        validationErrors.showInteractivePopup && submitted
                    }"
                  />
                </div>
              </div>
              <div class="field-hint text-centered">
                You can encourage your visitors to use the tracker more
                frequently, adding a small pulsing popup
              </div>
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Popup message field -->
          <div class="p-fluid p-mt-4" v-if="showInteractivePopup">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Popup message</div>
              <div class="p-col-9">
                <InputText
                  id="popup-message"
                  v-model="popupMessage"
                  :class="{
                    'p-invalid': validationErrors.popupMessage && submitted
                  }"
                />
                <div
                  v-show="validationErrors.popupMessage && submitted"
                  class="p-error"
                >
                  The Popup Message is required
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              Enter the text wich will be shown into the popup
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <i></i>
          <Button
            label="Next"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  name: "TrackerCreateFirstStep",
  data() {
    return {
      domain: "",
      searchMessage: "Search with your voice",
      useVoiceTyping: true,
      typeMessage: "Type with your voice",
      showInteractivePopup: true,
      popupMessage: "🎙 Speak to navigate within the website easier",
      submitted: false,
      validationErrors: {}
    };
  },
  methods: {
    nextPage() {
      this.submitted = true;
      if (this.validateForm()) {
        this.$emit("next-page", {
          formData: {
            domain: this.domain,
            search_message: this.searchMessage,
            use_voice_typing: this.useVoiceTyping,
            write_message: this.typeMessage,
            show_popup: this.showInteractivePopup,
            popup_message: this.popupMessage
          },
          pageIndex: 0
        });
      }
    },
    validateForm() {
      if (!this.domain.trim()) this.validationErrors["domain"] = true;
      else delete this.validationErrors["domain"];

      return !Object.keys(this.validationErrors).length;
    }
  }
};
</script>

<style scoped></style>
