<template>
  <div id="app">
    <div class="p-grid" v-if="email">
      <div
        class="p-col-2 position-fixed"
        style="background: #f7fafc; border-right: 1px solid #e3e8ed;"
      >
        <div class="p-d-flex">
          <router-link to="/dashboard" class="p-mx-auto"
            ><img
              src="@/assets/images/logo-icon.png"
              alt="Voxpow Dashboard"
              style="width: 60px"
          /></router-link>
        </div>

        <TieredMenu
          :model="mainMenuItems"
          orientation="vertical"
          class="p-mx-auto"
        />

        <TieredMenu
          :model="bottomMenuItems"
          orientation="vertical"
          class="p-mx-auto bottom-menu"
        />
      </div>
      <div class="p-col-10 p-offset-2">
        <div class="p-d-flex card">
          {{ email }} - {{ state }}
          <div class="p-ml-auto">
            <a href="https://docs.voxpow.com" target="_blank">
              <Button
                type="Button"
                icon="pi pi-question-circle"
                class="p-button p-button-icon-only p-button-rounded p-button-secondary p-button-text"
              />
            </a>
            <Button
              type="Button"
              icon="pi pi-cog"
              class="p-button p-button-icon-only p-button-rounded p-button-secondary p-button-text"
              @click="this.$router.push({ name: 'UserDetails' })"
            />
            <Button
              type="Button"
              icon="pi pi-user"
              class="p-button p-button-icon-only p-button-rounded p-button-secondary p-button-text"
              @click="this.$router.push({ name: 'UserDetails' })"
            />
          </div>
        </div>

        <Divider type="dashed" />

        <router-view />
      </div>

      <AppFooter />
    </div>

    <div class="p-grid p-mx-auto" v-if="!email">
      <router-view />
    </div>

    <!-- Toast Messaging Service -->
    <Toast />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Toast from "primevue/toast";
import AppFooter from "@/components/AppFooter.vue";
import TieredMenu from "primevue/tieredmenu";

export default {
  data() {
    return {
      mainMenuItems: [
        {
          label: "Dashboard",
          icon: "pi pi-th-large",
          to: "/dashboard"
        },
        {
          label: "Voice Trackers",
          icon: "pi pi-window-minimize",
          to: "/trackers"
        },
        {
          label: "Search Indexes",
          icon: "pi pi-search-plus",
          to: "/search-indexes"
        },
        {
          label: "Voice Widgets",
          icon: "pi pi-volume-up"
        },
        {
          label: "Stats",
          icon: "pi pi-chart-bar",
          to: "/stats"
        },
        {
          label: "Documentation",
          icon: "pi pi-briefcase",
          url: "https://docs.voxpow.com",
          target: "_blank"
        }
      ],
      bottomMenuItems: [
        {
          label: "Contact us",
          icon: "pi pi-comments",
          to: "/user"
        },
        {
          label: "Payments",
          icon: "pi pi-money-bill",
          to: "/user"
        },
        {
          label: "Affiliates",
          icon: "pi pi-users",
          to: "/user"
        },
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          to: "/logout"
        }
      ]
    };
  },
  components: {
    AppFooter,
    TieredMenu,
    Toast
  },
  created() {
    const userString = localStorage.getItem("user");
    const userInfoString = localStorage.getItem("userInfo");
    if (userString && userInfoString) {
      const userData = JSON.parse(userString);
      const userInfo = JSON.parse(userInfoString);
      this.$store.commit("user/SET_USER_DATA", userData);
      this.$store.commit("user/SET_USER_INFO", userInfo);
    }
  },
  computed: {
    ...mapGetters({
      email: "user/userEmail",
      state: "tracker/allState"
    })
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

.p-tieredmenu {
  border: 0 !important;
  background: #f7fafc !important;
  font-weight: 600 !important;
  margin-top: 3em;
}

.header-menu a,
div {
  font-size: small;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

h4 {
  font-size: 20px;
}
</style>
