import axios from "axios";
import UserService from "@/services/UserService";
import { ENDPOINT } from "@/utilities/constants";

export const namespaced = true;

export const state = {
  user: null
};

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;
    localStorage.setItem("user", JSON.stringify(userData));
  },
  CLEAR_USER_DATA() {
    localStorage.removeItem("user");
    localStorage.removeItem("userInfo");
    location.reload();
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  },
  CLEAR_USER_INFO() {
    localStorage.removeItem("userInfo");
    location.reload();
  },
  REFRESH_TOKEN(state, data) {
    state.user = data;
    localStorage.setItem("user", JSON.stringify(state.user));
    console.log("Token Updated");
  }
};

export const actions = {
  register({ commit }, credentials) {
    return axios
      .post(ENDPOINT + "/api/v1/token/", credentials)
      .then(({ data }) => {
        commit("SET_USER_DATA", data);
      });
  },
  refresh({ commit }, data) {
    commit("REFRESH_TOKEN", data);
  },
  login({ commit }, credentials) {
    return axios
      .post(ENDPOINT + "/api/v1/token/", credentials)
      .then(({ data }) => {
        commit("SET_USER_DATA", data);
      });
  },
  fetchInfo({ commit }) {
    return UserService.getDetails()
      .then(({ data }) => {
        commit("SET_USER_INFO", data);
        return data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  logout({ commit }) {
    commit("CLEAR_USER_DATA");
  }
};

export const getters = {
  loggedIn(state) {
    return !!state.user;
  },
  userEmail(state) {
    if (state.userInfo) {
      return state.userInfo.email;
    }
  },
  userName(state) {
    if (state.userInfo) {
      return state.userInfo.first_name;
    }
  }
};
