// Check if we are on localhost, then use the demo endpoint
let endpoint;
let cdnLink;
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  endpoint = "//localhost:8000";
  cdnLink = "";
} else {
  endpoint = "https://voxpow.com";
  cdnLink = "https://cdn.voxpow.com";
}
export const ENDPOINT = endpoint;

export const THEMES = [
  {
    path: cdnLink + "/static/themes/basic-left.css",
    name: "Standart style (Left)",
    image: "left/basic.png"
  },
  {
    path: cdnLink + "/static/themes/basic-right.css",
    name: "Standart style (Right)",
    image: "right/basic.png"
  },
  {
    path: cdnLink + "/static/themes/amethyst-left.css",
    name: "Amethyst coral (Left)",
    image: "left/amethyst-coral.png"
  },
  {
    path: cdnLink + "/static/themes/amethyst-right.css",
    name: "Amethyst coral (Right)",
    image: "right/amethyst-coral.png"
  },
  {
    path: cdnLink + "/static/themes/clouds-left.css",
    name: "Clouds (Left)",
    image: "left/clouds.png"
  },
  {
    path: cdnLink + "/static/themes/clouds-right.css",
    name: "Clouds (Right)",
    image: "right/clouds.png"
  },
  {
    path: cdnLink + "/static/themes/concrete-left.css",
    name: "Concrete (Left)",
    image: "left/concrete.png"
  },
  {
    path: cdnLink + "/static/themes/concrete-right.css",
    name: "Concrete (Right)",
    image: "right/concrete.png"
  },
  {
    path: cdnLink + "/static/themes/emerald-left.css",
    name: "Emerald (Left)",
    image: "left/emerald.png"
  },
  {
    path: cdnLink + "/static/themes/emerald-right.css",
    name: "Emerald (Right)",
    image: "right/emerald.png"
  },
  {
    path: cdnLink + "/static/themes/midnight-blue-left.css",
    name: "Midnight Blue (Left)",
    image: "left/midnight-blue.png"
  },
  {
    path: cdnLink + "/static/themes/midnight-blue-right.css",
    name: "Midnight Blue (Right)",
    image: "right/midnight-blue.png"
  },
  {
    path: cdnLink + "/static/themes/orange-left.css",
    name: "Orange (Left)",
    image: "left/orange.png"
  },
  {
    path: cdnLink + "/static/themes/orange-right.css",
    name: "Orange (Right)",
    image: "right/orange.png"
  },
  {
    path: cdnLink + "/static/themes/pomegranate-left.css",
    name: "Pomegranate (Left)",
    image: "left/pomegranate.png"
  },
  {
    path: cdnLink + "/static/themes/pomegranate-right.css",
    name: "Pomegranate (Right)",
    image: "right/pomegranate.png"
  },
  {
    path: cdnLink + "/static/themes/pomegranate-left.css",
    name: "Pumpkin (Left)",
    image: "left/pumpkin.png"
  },
  {
    path: cdnLink + "/static/themes/pomegranate-right.css",
    name: "Pumpkin (Right)",
    image: "right/pumpkin.png"
  },
  {
    path: cdnLink + "/static/themes/turquoise-left.css",
    name: "Turquoise (Left)",
    image: "left/turquoise.png"
  },
  {
    path: cdnLink + "/static/themes/turquoise-right.css",
    name: "Turquoise (Right)",
    image: "right/turquoise.png"
  }
];
export const LANGUAGES = [
  { code: "en-US", name: "English" },
  { code: "en-GB", name: "English (UK)" },
  { code: "fr-FR", name: "French" },
  { code: "de-DE", name: "German" },
  { code: "bg", name: "Bulgarian" },
  { code: "it-IT", name: "Italian" },
  { code: "cs", name: "Czech" },
  { code: "nl-NL", name: "Dutch" },
  { code: "el-GR", name: "Greek" },
  { code: "hu", name: "Hungarian" },
  { code: "is", name: "Icelandic" },
  { code: "id", name: "Indonesian" },
  { code: "ja", name: "Japanese" },
  { code: "ko", name: "Korean" },
  { code: "no-NO", name: "Norwegian" },
  { code: "pl", name: "Polish" },
  { code: "pt-PT", name: "Portuguese" },
  { code: "ro-RO", name: "Romanian" },
  { code: "ru", name: "Russian" },
  { code: "sr-SP", name: "Serbian" },
  { code: "sk", name: "Slovak" },
  { code: "es-ES", name: "Spanish" },
  { code: "sv-SE", name: "Swedish" },
  { code: "tr", name: "Turkish" },
  { code: "ar-EG", name: "Arabic" },
  { code: "zh-CN", name: "Mandarin Chinese" },
  { code: "zh-TW", name: "Chinese Traditional" },
  { code: "af", name: "Afrikaans" },
  { code: "fi", name: "Finnish" },
  { code: "he", name: "Hebrew" },
  { code: "ms-MY", name: "Malaysian" }
];
export const FALLBACK_TYPES = [
  { type: "search-url", name: "Search URL on the website" },
  { type: "static-popup", name: "Static Popup with information" },
  { type: "search-popup", name: "Popup with Search functionality" },
  { type: "custom-function", name: "Custom function with parameters" }
];
export const COMMAND_TYPES = [
  { type: "match_exact_redirection", name: "Exact match => Redirection" },
  { type: "match_part_redirection", name: "Part match => Redirection" },
  { type: "match_exact_js_action", name: "Exact match => JS Action" }
];
