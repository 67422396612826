<template>
  <div class="tracker-create-steps p-p-2 p-mt-3">
    <Card>
      <template #title>
        <h3 class="text-centered">Language and Theme (style)</h3>
      </template>
      <template #content>
        <div class="p-col-10 p-offset-1">
          <!-- Language -->
          <div class="p-fluid p-mt-2">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Language</div>
              <div class="p-col-9">
                <Dropdown
                  v-model="selectedLanguage"
                  :options="languages"
                  optionLabel="name"
                  placeholder="Select a language"
                  :filter="true"
                  id="language"
                />
              </div>
            </div>
            <div class="field-hint text-centered">
              Language of the customers, who will use the tracker
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Theme -->
          <div class="p-fluid p-mt-5">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Themes</div>
              <div class="p-col-9">
                <Dropdown
                  v-model="selectedTheme"
                  :options="themes"
                  optionLabel="name"
                  placeholder="Select a theme"
                  :filter="true"
                  id="theme"
                />
              </div>
            </div>
            <div class="field-hint text-centered">
              Design of your Voice Tracker. You can choose the pre-defined or
              custom design
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Design visualization -->
          <div class="p-fluid p-mt-5">
            <div class="p-grid">
              <div class="p-col-12 text-centered">
                <img
                  :src="
                    require(`@/assets/images/trackers/${selectedTheme.image}`)
                  "
                  :alt="selectedTheme.name"
                />
              </div>
            </div>
            <div class="field-hint text-centered">
              Just a demo preview of your tracker
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
          <Button
            label="Next"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { LANGUAGES, THEMES } from "@/utilities/constants";

export default {
  name: "TrackerCreateFirstStep",
  data() {
    return {
      languages: LANGUAGES,
      selectedLanguage: LANGUAGES[0],
      themes: THEMES,
      selectedTheme: THEMES[0],
      validationErrors: {}
    };
  },
  methods: {
    nextPage() {
      this.$emit("next-page", {
        formData: {
          language: this.selectedLanguage.code,
          theme: this.selectedTheme.path
        },
        pageIndex: 2
      });
    },
    prevPage() {
      this.$emit("prev-page", { pageIndex: 2 });
    },
    validateForm() {
      if (!this.domain.trim()) this.validationErrors["domain"] = true;
      else delete this.validationErrors["domain"];

      return !Object.keys(this.validationErrors).length;
    }
  }
};
</script>

<style scoped></style>
