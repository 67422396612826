<template>
  <div class="p-grid">
    <div class="p-col-11 p-p-4 p-m-6 tracker-container">
      <h1 class="text-centered">Create Tracker</h1>
      <Steps :model="createTrackerSteps" />

      <router-view
        v-slot="{ Component }"
        :formData="formObject"
        @prev-page="prevPage($event)"
        @next-page="nextPage($event)"
        @complete="complete"
      >
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackerCreate",
  data() {
    return {
      createTrackerSteps: [
        {
          label: "Main Settings",
          to: "/tracker/create/domain"
        },
        {
          label: "Advanced Configurations",
          to: "/tracker/create/settings"
        },
        {
          label: "Language and Style",
          to: "/tracker/create/language-style"
        },
        {
          label: "Confirmation",
          to: "/tracker/create/confirmation"
        }
      ],
      formObject: {},
      languages: [
        { name: "English", code: "en-EN" },
        { name: "Bulgarian", code: "bg-BG" },
        { name: "Egypt", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" }
      ],
      validationErrors: {}
    };
  },
  methods: {
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }

      this.$router.push(this.createTrackerSteps[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.createTrackerSteps[event.pageIndex - 1].to);
    },
    complete(tracker) {
      this.$store
        .dispatch("tracker/createTracker", tracker)
        .then(response => {
          this.$toast.add({
            severity: "success",
            summary: "You created new Voice Tracker",
            detail: "Succesfully created new tracker.",
            life: 3000
          });

          this.$router.push({
            name: "TrackerDetails",
            params: { code: response.code }
          });
        })
        .catch(error => {
          // Handle the situation if the backend return an error
          if (error.response.data) {
            const errors = error.response.data;
            for (const [key, value] of Object.entries(errors)) {
              this.validationErrors[key] = value;
            }
          }
        });
    }
  },
  components: {}
};
</script>

<style scoped></style>
