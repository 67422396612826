<template>
  <h1>Voice Trackers</h1>

  <template v-if="!isLoading">
    <div class="p-grid">
      <div class="trackers p-col-12 p-md-6 p-lg-8">
        <TrackerCard
          v-for="tracker in trackers"
          :key="tracker.uuid"
          :tracker="tracker"
        />
      </div>
      <div class="p-col-12 p-md-6 p-lg-3 p-offset-7 position-fixed">
        <div class="p-grid" style="max-width: 300px">
          <router-link :to="{ name: 'TrackerCreateFirstStep' }">
            <div class="card summary light-card">
              <div class="p-grid">
                <div class="icon p-col-12 p-md-6 p-lg-3">
                  <i class="pi pi-plus-circle"></i>
                </div>
                <div class="p-col-12 p-md-6 p-lg-9">
                  <div class="title text-centered">Create Voice Tracker</div>
                </div>
              </div>
            </div>
          </router-link>

          <div class="p-mt-2">
            <div class="card summary">
              <div class="p-grid">
                <div class="icon p-col-12 p-md-6 p-lg-3">
                  <i class="pi pi-search-plus"></i>
                </div>
                <div class="p-col-12 p-md-6 p-lg-9">
                  <div class="title text-centered">User Voice Searches</div>
                  <div class="detail text-centered">0 available so far</div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-mt-2">
            <div class="card summary">
              <div class="p-grid">
                <div class="icon p-col-12 p-md-6 p-lg-3">
                  <i class="pi pi-tag"></i>
                </div>
                <div class="p-col-12 p-md-6 p-lg-9">
                  <div class="title text-centered">Sentiment Analysis</div>
                  <div class="detail text-centered">0 available so far</div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-mt-2">
            <div class="explore-pro-card">
              <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                  <div class="title">Discover the PRO</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                  <Button label="Learn More" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-if="isLoading">
    <div class="p-grid">
      <div class="trackers p-col-12 p-md-6 p-lg-8">
        <div class="p-field p-fluid" v-for="n in 5" :key="n">
          <Skeleton width="100%" height="1.2rem" class="p-field p-fluid" />
          <Skeleton width="70%" height="1.1rem" class="p-field p-fluid" />
          <Skeleton width="60%" height="1.1rem" class="p-field p-fluid" />
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-lg-3 p-offset-7 position-fixed">
        <div class="p-field p-fluid" v-for="n in 6" :key="n">
          <Skeleton width="70%" height="1.2rem" class="p-field p-fluid" />
          <Skeleton width="30%" height="1.1rem" class="p-field p-fluid" />
          <Skeleton width="60%" height="1.3rem" class="p-field p-fluid" />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import TrackerService from "@/services/TrackerService.js";
import TrackerCard from "@/components/trackers/TrackerCard";

export default {
  name: "TrackerList",
  components: {
    TrackerCard
  },
  data() {
    return {
      trackers: null,
      isLoading: true
    };
  },
  created() {
    TrackerService.getTrackers()
      .then(response => {
        if (response) {
          this.trackers = response.data.results;
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style scoped>
.trackers {
  display: flex;
  flex-direction: column;
}
</style>
