<template>
  <div class="layout-footer">
    <span class="footer-text">Voxpow Dashboard</span>
    <img src="@/assets/images/logo-dark.png" alt="Voxpow" width="80" />
  </div>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped>
.footer-text {
  margin-right: 5px;
}
</style>
