<template>
  <div class="tracker-create-steps p-p-2 p-mt-3">
    <Card>
      <template #title>
        <h3 class="text-centered">Confirmation</h3>
      </template>
      <template #content>
        <div class="p-col-10 p-offset-1">
          <!-- Domain -->
          <div class="p-fluid">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Domain</div>
              <div class="p-col-9 bigger-text">{{ formData.domain }}</div>
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Search Message -->
          <div class="p-fluid">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Search Message</div>
              <div class="p-col-9 bigger-text">
                {{ formData.search_message }}
              </div>
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Type Message -->
          <div class="p-fluid">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Type Message</div>
              <div class="p-col-9 bigger-text">
                {{ formData.type_message }}
              </div>
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Fallback Type -->
          <div class="p-fluid">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Fallback Type</div>
              <div class="p-col-9 bigger-text">
                {{ formData.fallback_type }}
              </div>
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Language -->
          <div class="p-fluid">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Language</div>
              <div class="p-col-9 bigger-text">
                {{ formData.language }}
              </div>
            </div>
            <Divider type="dashed" />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
          <Button
            label="Complete"
            @click="complete()"
            icon="pi pi-check"
            iconPos="right"
            class="p-button-success"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    formData: Object
  },
  name: "TrackerCreateFirstStep",
  methods: {
    prevPage() {
      this.$emit("prev-page", { pageIndex: 3 });
    },
    complete() {
      this.$emit("complete", this.formData);
    }
  }
};
</script>

<style scoped></style>
