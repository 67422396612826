<template>
  <div class="tracker-create-steps p-p-2 p-mt-3">
    <Card>
      <template #title>
        <h3 class="text-centered">Additional settings</h3>
      </template>
      <template #content>
        <div class="p-col-10 p-offset-1">
          <!-- Fallback Type field -->
          <div class="p-fluid">
            <div class="p-field p-mt-2">
              <div class="p-grid">
                <div class="p-col-3 bigger-text">Fallback Type</div>
                <div class="p-col-9">
                  <Dropdown
                    v-model="fallbackType"
                    :options="fallbackTypes"
                    optionLabel="name"
                    placeholder="Select a Fallback Type"
                    :filter="true"
                    id="fallback-type"
                  />
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              The fallback type when the user search doesn't match any patterns
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Search URL field -->
          <div class="p-fluid p-mt-4" v-if="fallbackType.type === 'search-url'">
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Search URL</div>
              <div class="p-col-9">
                <InputText
                  id="search-url"
                  v-model="searchUrl"
                  :class="{
                    'p-invalid': validationErrors.searchUrl && submitted
                  }"
                />
                <div
                  v-show="validationErrors.searchUrl && submitted"
                  class="p-error field-hint text-centered"
                >
                  Your searchUrl is required.
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              The URL of your built-in search functionality, for example:
              https://example.com/?s=
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Custom JS function field -->
          <div
            class="p-fluid p-mt-4"
            v-if="fallbackType.type === 'custom-function'"
          >
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Custom JS Function</div>
              <div class="p-col-9">
                <InputText
                  id="custom-fallback-function"
                  v-model="customFallbackFunction"
                  :class="{
                    'p-invalid':
                      validationErrors.customFallbackFunction && submitted
                  }"
                />
                <div
                  v-show="validationErrors.customFallbackFunction && submitted"
                  class="p-error field-hint text-centered"
                >
                  Custom function is not correct
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              The name of the custom JavaScript function, which you will use for
              a fallback
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Interactive Fallback Prefix field -->
          <div
            class="p-fluid p-mt-4"
            v-if="
              fallbackType.type === 'static-popup' ||
                fallbackType.type === 'search-popup'
            "
          >
            <div class="p-grid">
              <div class="p-col-3 bigger-text">Interactive Fallback Prefix</div>
              <div class="p-col-9">
                <InputText
                  id="interactive-fallback-prefix"
                  v-model="interactiveFallbackPrefix"
                  :class="{
                    'p-invalid':
                      validationErrors.interactiveFallbackPrefix && submitted
                  }"
                />
                <div
                  v-show="
                    validationErrors.interactiveFallbackPrefix && submitted
                  "
                  class="p-error field-hint text-centered"
                >
                  Your interactiveFallbackPrefix is required.
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              The text which will be into the Interactive Fallback Popup heading
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Interactive Fallback Content field -->
          <div
            class="p-fluid p-mt-4"
            v-if="
              fallbackType.type === 'static-popup' ||
                fallbackType.type === 'search-popup'
            "
          >
            <div class="p-grid">
              <div class="p-col-12 bigger-text">
                Interactive Fallback Content
              </div>
              <div class="p-col-12">
                <Textarea
                  :autoResize="true"
                  rows="5"
                  cols="30"
                  id="interactive-fallback-content"
                  v-model="interactiveFallbackContent"
                  :class="{
                    'p-invalid':
                      validationErrors.interactiveFallbackContent && submitted
                  }"
                />
                <div
                  v-show="
                    validationErrors.interactiveFallbackContent && submitted
                  "
                  class="p-error field-hint text-centered"
                >
                  Your interactiveFallbackContent is required.
                </div>
              </div>
            </div>
            <div class="field-hint text-centered">
              Main content (HTML) of the Interactive Fallback Popup
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Save User Searches field -->
          <div class="p-fluid p-mt-4">
            <div class="p-grid">
              <div class="p-col-7 bigger-text">Save User Searches</div>
              <div class="p-col-5">
                <InputSwitch
                  id="save-user-searches"
                  v-model="saveUserSearches"
                />
              </div>
            </div>
            <div class="field-hint text-centered">
              Should we keep track of the user searches? You can analyze them
              after in the dashboard
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Send event to Google Analytics field -->
          <div class="p-fluid p-mt-4">
            <div class="p-grid">
              <div class="p-col-7 bigger-text">Send Events Analytics</div>
              <div class="p-col-5">
                <InputSwitch
                  id="send-events-analytics"
                  v-model="sendEventsAnalytics"
                />
              </div>
            </div>
            <div class="field-hint text-centered">
              Do you want to send events to Google Analytics to better
              understand your customers?
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Remember Status field -->
          <div class="p-fluid p-mt-4">
            <div class="p-grid">
              <div class="p-col-7 bigger-text">Remember Status</div>
              <div class="p-col-5">
                <InputSwitch id="remember-status" v-model="rememberStatus" />
              </div>
            </div>
            <div class="field-hint text-centered">
              Should we remember when the user clicks the button to turn on
              Speech Recognition on your website?
            </div>
            <Divider type="dashed" />
          </div>

          <!-- Show Voice Tracker only on mobile field -->
          <div class="p-fluid p-mt-4">
            <div class="p-grid">
              <div class="p-col-7 bigger-text">Show only on Mobile</div>
              <div class="p-col-5">
                <InputSwitch id="show-only-mobile" v-model="showOnlyOnMobile" />
              </div>
            </div>
            <div class="field-hint text-centered">
              Turn on this option to restrict the desktop users to use Voxpow
              and show only to mobile devices
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
          <Button
            label="Next"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { FALLBACK_TYPES } from "@/utilities/constants";

export default {
  name: "TrackerCreateFirstStep",
  data() {
    return {
      fallbackTypes: FALLBACK_TYPES,
      fallbackType: FALLBACK_TYPES[0],
      interactiveFallbackPrefix: "〰 Voice command:",
      interactiveFallbackContent: "",
      searchUrl: null,
      customFallbackFunction: null,
      saveUserSearches: true,
      sendEventsAnalytics: true,
      rememberStatus: true,
      showOnlyOnMobile: false,
      validationErrors: {}
    };
  },
  methods: {
    nextPage() {
      this.$emit("next-page", {
        formData: {
          fallback_type: this.fallbackType.type,
          interactive_fallback_prefix: this.interactiveFallbackPrefix,
          interactive_fallback_content: this.interactiveFallbackContent,
          search_url: this.searchUrl,
          custom_fallback_function: this.customFallbackFunction,
          save_searches: this.saveUserSearches,
          send_events_analytics: this.sendEventsAnalytics,
          remember_status: this.rememberStatus,
          show_only_on_mobile: this.showOnlyOnMobile
        },
        pageIndex: 1
      });
    },
    prevPage() {
      this.$emit("prev-page", { pageIndex: 1 });
    },
    validateForm() {
      if (!this.domain.trim()) this.validationErrors["domain"] = true;
      else delete this.validationErrors["domain"];

      return !Object.keys(this.validationErrors).length;
    }
  }
};
</script>

<style scoped></style>
