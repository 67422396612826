<template>
  <router-link
    class="tracker-link"
    :to="{ name: 'SearchIndexDetails', params: { uuid: searchIndex.uuid } }"
  >
    <Card class="tracker-card">
      <template #title>{{ searchIndex.internal_name }}</template>

      <template #content>
        <div class="p-grid">
          <div class="p-col-9">
            <div class="p-grid tracker-card-element">
              <div class="p-col-4">
                <Tag
                  class="p-mr-2 tracker-tag"
                  value="Public name"
                  rounded
                ></Tag>
              </div>
              <div class="p-col-8">
                <div class="tracker-card-element">{{ searchIndex.name }}</div>
              </div>
            </div>
            <div class="p-grid tracker-card-element">
              <div class="p-col-4">
                <Tag
                  class="p-mr-2 tracker-tag"
                  value="Auto Update"
                  rounded
                ></Tag>
              </div>
              <div class="p-col-8">
                <div class="tracker-card-element">
                  {{ searchIndex.auto_update }}
                </div>
              </div>
            </div>

            <div class="p-grid tracker-card-element">
              <div class="p-col-4">
                <Tag class="p-mr-2 tracker-tag" value="Documents" rounded></Tag>
              </div>
              <div class="p-col-8">
                <div class="tracker-card-element">
                  {{ searchIndex.documents.length }}
                </div>
              </div>
            </div>
            <div class="p-grid tracker-card-element">
              <div class="p-col-4">
                <Tag
                  class="p-mr-2 tracker-tag"
                  value="Max Results"
                  rounded
                ></Tag>
              </div>
              <div class="p-col-8">
                <div class="tracker-card-element">
                  {{ searchIndex.max_results }}
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-3 text-centered">
            <img
              src="@/assets/images/icons/search-index.png"
              alt="Voxpow Tracker"
              class="tracker-icon p-mt-3"
            />
          </div>
        </div>
      </template>
    </Card>
  </router-link>
</template>

<script>
export default {
  props: {
    searchIndex: {
      type: Object,
      required: true
    }
  },
  components: {}
};
</script>

<style scoped></style>
